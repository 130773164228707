// eslint-disable-next-line no-restricted-imports -- We already track via the button so no need to track LinkOverlay as well.
import { Icon, LinkBox, LinkOverlay } from "@chakra-ui/react";
import type { IconType } from "react-icons";
import type { ButtonProps } from "~/ui/tracked/form/button";
import { Button } from "~/ui/tracked/form/button";
import type { TrackProps } from "~/ui/tracked/types";
import { extractPathname } from "~/ui/navigation/extract-pathname";

interface LinkButtonProps extends ButtonProps {
  eventName: TrackProps["eventName"];
  eventProperties?: TrackProps["eventProperties"];
  IconComponent?: IconType;
  href: string;
  isExternal?: boolean;
  children: React.ReactNode;
}

export function ButtonLink({
  eventName,
  eventProperties,
  IconComponent,
  href,
  isExternal = false,
  children,
  ...restProps
}: LinkButtonProps): JSX.Element {
  return (
    <LinkBox>
      <Button
        colorScheme="rose"
        eventName={eventName}
        eventProperties={{
          to: extractPathname(href),
          ...eventProperties,
        }}
        leftIcon={IconComponent ? <Icon as={IconComponent} color="white" /> : undefined}
        width="fit-content"
        {...restProps}
      >
        <LinkOverlay href={href} isExternal={isExternal}>
          {children}
        </LinkOverlay>
      </Button>
    </LinkBox>
  );
}
