// import ModalVideo from "react-modal-video";
// import { useState } from "react";
import ReactModalStyles from "react-modal-video/css/modal-video.css";
import { Footer } from "./_sections/footer";
import { Header } from "./_sections/header";
import { Hero } from "./_sections/hero";
// import { TemplateList } from "./_sections/template-list";
import { Features } from "~/routes/_public/_landing-page+/_sections/feature-list";
// import { FaqBlock } from "~/routes/_public/_landing-page+/_sections/faq";
import { DemoCta } from "~/routes/_public/_landing-page+/_sections/demo-cta";
import { SocialProof } from "~/routes/_public/_landing-page+/_sections/social-proof";
// import { Integrations } from "~/routes/_public/_landing-page+/_sections/integrations";

export function headers(): {"Cache-Control": string;} {
  return {
    "Cache-Control": "max-age=0, s-maxage=86400"
  };
}

export function links(): {rel: string;href: string;}[] {
  return [{ rel: "stylesheet", href: ReactModalStyles }];
}

// eslint-disable-next-line import/no-default-export -- Remix route
export default function LandingPage(): JSX.Element {
  return (
    <>
      <Header />
      <Hero />
      <SocialProof />
      <Features />
      {/* <Integrations /> */}

      {/* <TemplateList /> */}
      {/* <Pricing /> */}
      <DemoCta />

      {/* <FaqBlock /> */}
      <Footer />
    </>);

}