// import { BsFillPlayCircleFill } from "react-icons/bs";
import ModalVideo from "react-modal-video";
// import { MdOutlineArrowRightAlt } from "react-icons/md";
// import { useState, type Dispatch, type SetStateAction } from "react";
// import { Dialog } from "@headlessui/react";
// import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
// import { ButtonLink } from "~/ui/navigation/button-link";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { Link as RemixLink } from "@remix-run/react";
// eslint-disable-next-line no-restricted-imports
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import { PrimaryBtn } from "~/ui/core/primary-button";

// const navigation = [
//   { name: "Product", href: "#" },
//   { name: "Features", href: "#" },
//   { name: "Marketplace", href: "#" },
//   { name: "Company", href: "#" },
// ];

export function Hero(): JSX.Element {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <>
      <ModalVideo
        autoplay
        channel="vimeo"
        isOpen={isVideoOpen}
        onClose={(): void => setIsVideoOpen(false)}
        videoId="855474372"
      />
      <div className="isolate bg-white">
        {/* <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
      <svg
        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
        viewBox="0 0 1155 678"
      >
        <path
          fill="url(#9b2541ea-d39d-499b-bd42-aeea3e93f5ff)"
          fillOpacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="9b2541ea-d39d-499b-bd42-aeea3e93f5ff"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9089FC" />
            <stop offset={1} stopColor="#FF80B5" />
          </linearGradient>
        </defs>
      </svg>
    </div> */}
        {/* <div className="px-6 pt-6 lg:px-8">
      <nav className="flex items-center justify-between" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              className="h-8"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-gray-900 hover:bg-gray-400/10"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div> */}
        <main>
          <div className="relative py-16 sm:py-24 lg:pb-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Clean, Enrich and Qualify leads using AI
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Stop manually Googling your leads. Start using GPT to automatically research your
                  leads on the internet and answer questions.
                </p>
                <div className="mt-10 flex flex-wrap items-center justify-center gap-6">
                  {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
      Learn more <span aria-hidden="true">→</span>
    </a> */}
                  {/* <ButtonLink
                    className="normal-case"
                    eventName="Clicked on Hero Book a demo button"
                    href="https://calendly.com/get-double/intro-to-double"
                    size="lg"
                  >
                    Book a Demo
                  </ButtonLink> */}

                  <LinkBox>
                    <PrimaryBtn eventName="Clicked on Get Started">
                      <LinkOverlay as={RemixLink} to="/signup">
                        Get Started
                      </LinkOverlay>
                    </PrimaryBtn>
                  </LinkBox>

                  <button
                    className="flex items-center text-rose-600 hover:text-rose-700"
                    onClick={(): void => setIsVideoOpen(true)}
                    type="button"
                  >
                    <div className="px-md">
                      <BsFillPlayCircleFill className="" size={30} />
                    </div>
                    <div className="flex flex-col text-left font-semibold">
                      <div>Watch Demo</div>
                      <div className="text-text-secondary-black text-sm normal-case">1 min</div>
                    </div>
                  </button>
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <button
                    className="thumbnail-container group relative grid grid-cols-1 place-items-center "
                    onClick={(): void => {
                      setIsVideoOpen(true);
                    }}
                    type="button"
                  >
                    <img
                      alt="Demo video thumbnail"
                      className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                      height={1442}
                      src="/thumbnail2.png"
                      width={2432}
                      // className="drop-shadow-md rounded-t-xl sm:rounded-t-2xl"
                    />
                    <div className="xs:translate-y-0 absolute flex translate-y-5 flex-col items-center">
                      <div className="z-10 max-w-min rounded-full border-[6px]  border-indigo-300/50 transition duration-300 ease-in-out group-hover:scale-110 group-hover:border-indigo-400/50">
                        <BsFillPlayCircleFill className="text-indigo-600" size={60} />
                      </div>
                      <div className="flex -translate-y-2 flex-col items-center rounded-full bg-stone-50 px-6 pb-3 pt-4 tracking-tight  opacity-0 drop-shadow-md transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                        <div className="font-medium">Learn how to automate your work</div>
                        <div className="flex items-center text-sm font-semibold">
                          <div className="mr-sm text-stone-400">1 min</div>
                          <div className="">Watch Now</div>
                          <MdOutlineArrowRightAlt size="25" />
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
      <svg
        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
        viewBox="0 0 1155 678"
      >
        <path
          fill="url(#b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1)"
          fillOpacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="b9e4a85f-ccd5-4151-8e84-ab55c66e5aa1"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9089FC" />
            <stop offset={1} stopColor="#FF80B5" />
          </linearGradient>
        </defs>
      </svg>
    </div> */}
          </div>
        </main>
      </div>
    </>
  );
}
