import type { ReactNode } from "react";
import { MdSearch } from "react-icons/md";
// import stripeLogo from "./imgs/stripe.png";

function CitationBubble({ children, href }: { children: ReactNode; href: string }): JSX.Element {
  return (
    <div className="animate-wait-bounce inline-block">
      <a className="text-blue-500 " href={href} rel="noopener noreferrer" target="_blank">
        <sup className="inline-block">
          <span className="text-primary-foreground mx-0.5 flex h-5 w-5 items-center justify-center rounded-full bg-rose-700 px-1 py-1 text-[0.6rem] leading-none transition-colors duration-300 hover:bg-blue-500 hover:text-white">
            {children}
          </span>
        </sup>
      </a>
    </div>
  );
}

export function Features(): JSX.Element {
  return (
    <div className="gap-xl flex flex-col bg-white py-16 lg:py-16">
      <div className="#feature gap-lg lg:gap-xl mx-auto flex max-w-prose flex-col px-6 lg:max-w-7xl lg:flex-row lg:px-8">
        <div className="lg:basis-[170rem]">
          <h1 className="text-3xl font-bold">Research thousands of prospects at once</h1>
          <h2 className="mt-md text-lg text-gray-600">
            Use AI to search the web and research your prospects. Ask questions like:
          </h2>
          <ul className="text-md mt-sm pl-md text-gray-600">
            {/* 
            Is this a remote first company?
            Are they SOC II compliant?
            Is this a b2b company?
            Is this a company building API as their primary product?
            Is this a marketplace that processes payments?
            Is their primary product a mobile app?
             */}
            <li className="">
              Is{" "}
              <span className="px-xs rounded-sm border border-slate-200 bg-slate-100 font-medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              a remote-first company?
            </li>
            <li className="">
              Is{" "}
              <span className="px-xs rounded-sm border border-slate-200 bg-slate-100 font-medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              SOC II compliant?
            </li>
            <li className="">
              Is{" "}
              <span className="px-xs rounded-sm border border-slate-200 bg-slate-100 font-medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              a b2b company?
            </li>
            <li className="">
              Is{" "}
              <span className="px-xs rounded-sm border border-slate-200 bg-slate-100 font-medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              a company building API as their primary product?
            </li>
            <li className="">
              Is{" "}
              <span className="px-xs rounded-sm border border-slate-200 bg-slate-100 font-medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              a marketplace that processes payments?
            </li>
            <li className="">
              Is{" "}
              <span className="px-xs rounded-sm border border-slate-200 bg-slate-100 font-medium">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>{" "}
              a company building a mobile app as their primary product?
            </li>
          </ul>
        </div>
        <div>
          <div className="p-sm flex items-center rounded border">
            <MdSearch className="mr-sm text-gray-300" />
            <div>
              Does&nbsp;
              <span className="px-xs inline-block rounded-sm border border-indigo-200 bg-indigo-100 font-medium">
                GOAT
              </span>
              &nbsp;have a call center?
            </div>
          </div>
          <hr className="m-md" />
          <div className="text-muted-foreground p-md rounded border">
            <div className="font-bold">Answered by Double</div>
            GOAT, the online marketplace for high-end sneakers, does not have a call center. The
            company does not provide a customer service phone number, as indicated in their support
            page
            <CitationBubble href="https://support.goat.com/hc/en-us/articles/360042885191-Does-GOAT-have-a-phone-number-I-can-call-">
              1
            </CitationBubble>
            . Instead, they offer support through a request submission system, which is available
            24/7{" "}
            <CitationBubble href="https://support.goat.com/hc/en-us/articles/115004608027-How-do-I-contact-you-">
              2
            </CitationBubble>
            .
          </div>
        </div>
      </div>
      {/* <EmailPersonalization /> */}
      {/* <div className="#feature mx-auto max-w-prose lg:max-w-7xl px-6 lg:px-8 flex flex-col gap-lg lg:flex-row lg:gap-xl">
        <div className="#text flex flex-col lg:basis-1/2">
          <h1 className="font-bold text-3xl mb-md">
            Find companies that match your niche criteria
          </h1>
          <h2 className="text-lg mb-lg text-gray-600">
            Go beyond the basic company size, industry, and location filters everyone else is using.
            Get data from LinkedIn, Crunchbase, BuiltWith, and more.
          </h2>
          <div className="flex gap-md h-8 justify-evenly">
            <img alt="Linkedin Logo" className="object-contain h-full" src={linkedinLogo} />
            <img alt="Crunchbase Logo" className="object-contain h-full" src={crunchbaseLogo} />
            <img alt="BuiltWith Logo" className="object-contain h-full" src={builtwithLogo} />
            <img alt="Google Maps Logo" className="object-contain h-full" src={googleMapsLogo} />
          </div>
        </div>

        <div className="#visual flex flex-col gap-lg items-center lg:basis-1/2">
          <div className="flex flex-col gap-md items-center">
            <div className="#chips flex justify-around gap-sm ">
              <div className="#chip flex items-center border rounded-md px-sm py-xs text-sm whitespace-nowrap	 ">
                &gt;100 employees
              </div> */}
      {/* <div className="#chip flex items-center border rounded-md px-sm py-xs text-sm whitespace-nowrap min-w-max	">
                Uses
                <img
                  alt="Stripe Logo"
                  className="object-contain h-6 inline-block"
                  src={stripeLogo}
                />
              </div> */}
      {/* <div className="#chip flex items-center border rounded-md px-sm py-xs text-sm whitespace-nowrap	">
                🇺🇸 United States
              </div> */}
      {/* <div className="#chip flex items-center border rounded-md px-sm py-xs text-sm whitespace-nowrap	">
                Raised &gt;$100M
              </div> */}
      {/* </div> */}
      {/* <HiPlus /> */}
      {/* <div className="inline-flex items-center justify-center px-md py-xs border-2 border-indigo-600 rounded-full self-center font-medium text-lg">
              <HiMagnifyingGlass className="mr-sm" />
              Marketplaces for collectibles
            </div>
          </div>
          <div className="flex gap-lg">
            <div className="flex flex-col ">
              <div className="text-center mb-md font-bold text-muted-foreground">
                Other Providers
              </div>
              <div className="text-muted-foreground text-center">❌ No results</div>
            </div>
            <div className="flex flex-col ">
              <div className="text-center mb-md font-bold">Ours</div>
              <div className="flex flex-col gap-xs"> */}
      {/* <img src={goldinLogo} alt="Goldin Logo" className="h-6 object-contain" />
                <img src={goatLogo} alt="GOAT Logo" className="h-6 object-contain" />
                <img src={whatnotLogo} alt="Whatnot Logo" className="h-6 object-contain" />
                <img src={artsyLogo} alt="Artsy Logo" className="h-6 object-contain" />
                <img src={stockxLogo} alt="StockX Logo" className="h-6 object-contain" /> */}
      {/* <div>GOAT, Whatnot, StockX, ...</div> */}
      {/* <div>1. GOAT</div>
                <div>2. Whatnot</div>
                <div>3. StockX</div>
                <div>...</div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "} */}
      <div className="#feature gap-lg lg:gap-xl mx-auto flex max-w-prose flex-col-reverse px-6 lg:max-w-7xl lg:flex-row lg:px-8">
        <div className="#visual gap-lg flex flex-col items-center lg:basis-1/2">
          <div className="relative overflow-x-auto">
            <table className="w-full text-left text-sm ">
              <thead className="bg-gray-50 text-xs uppercase text-gray-700  ">
                <tr>
                  <th className="hidden px-6 py-3 sm:table-cell" scope="col">
                    Name
                  </th>
                  <th className="hidden px-6 py-3 sm:table-cell" scope="col">
                    Linkedin
                  </th>
                  <th className="table-cell px-6 py-3 sm:table-cell" scope="col">
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b bg-white ">
                  <th
                    className="hidden whitespace-nowrap px-6 py-4 font-medium text-gray-900 sm:table-cell"
                    scope="row"
                  >
                    Alice Nakamura
                  </th>
                  <td className="hidden px-6 py-4 sm:table-cell">/alicenakamura</td>
                  <td className="table-cell whitespace-nowrap px-6 py-4 sm:table-cell">
                    alice.nakamura@amazon.com ✅
                  </td>
                </tr>
                <tr className="border-b bg-white ">
                  <th
                    className="hidden whitespace-nowrap px-6 py-4 font-medium text-gray-900 sm:table-cell"
                    scope="row"
                  >
                    Rajesh Khanna
                  </th>
                  <td className="hidden px-6 py-4 sm:table-cell">/rajeshkhanna</td>
                  <td className="table-cell whitespace-nowrap px-6 py-4 sm:table-cell">
                    rajesh.khanna@rippling.com ✅
                  </td>
                </tr>
                <tr className="bg-white ">
                  <th
                    className="hidden whitespace-nowrap px-6 py-4 font-medium text-gray-900 sm:table-cell"
                    scope="row"
                  >
                    Bianca Rodrigues
                  </th>
                  <td className="hidden px-6 py-4 sm:table-cell">/biancarodrigues</td>
                  <td className="table-cell whitespace-nowrap px-6 py-4 sm:table-cell">
                    bianca.rodrigues@goat.com ✅
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="#text flex flex-col lg:basis-1/2">
          <h1 className="mb-md text-3xl font-bold">Get verified emails</h1>
          <h2 className="mb-lg text-lg text-gray-600">
            More comprehensive than Apollo and RocketReach, we aggregate 10+ private data sources to
            find verified emails for your prospects.
          </h2>
        </div>
      </div>
    </div>
  );
}
