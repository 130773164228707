import YCLogo from "./imgs/ycombinator.svg";
import AmazonLogo from "./imgs/amazon.svg";
import IndeedLogo from "./imgs/indeed.svg";
import GartnerLogo from "./imgs/gartner.svg";

export function SocialProof(): JSX.Element {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col sm:flex-row gap-xl items-center justify-center">
      <img alt="Backed by YCombinator" className="h-20 object-contain" src={YCLogo} />

      <div className="flex flex-col gap-md">
        <div className="text-secondary-foreground font-medium text-lg">
          Join the world&apos;s best sales and marketing teams
        </div>
        <div className="flex gap-md justify-around">
          <div className="h-12">
            <img
              alt="Amazon"
              className=" m-2 h-8 object-contain grayscale brightness-50"
              src={AmazonLogo}
            />
          </div>
          <div className="h-12">
            <img
              alt="Indeed"
              className=" h-8 object-contain grayscale brightness-50"
              src={IndeedLogo}
            />
          </div>
          <div className="h-12">
            <img
              alt="Gartner"
              className=" h-8 object-contain grayscale brightness-50"
              src={GartnerLogo}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
