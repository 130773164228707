import { Icon } from "@chakra-ui/react";
import { BiRightArrowAlt } from "react-icons/bi";
import { ButtonLink } from "~/ui/navigation/button-link";

export function DemoCta(): JSX.Element {
  return (
    <div className="bg-indigo-100">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-xl">
        <div className="basis-3/5 flex flex-col gap-md mb-lg">
          <h2 className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Want to get more leads and improve your conversion?
          </h2>
          <p className="text-gray-700">
            We&apos;ll show you how the top 1% of outbound campaigns are converting their prospects
            with highly targeted messages that convert 3x better.
          </p>
        </div>
        <ButtonLink
          className="normal-case"
          eventName="Clicked on CTA Book a demo button"
          href="https://calendly.com/get-double/intro-to-double"
          size="lg"
        >
          Get a demo <Icon as={BiRightArrowAlt} />{" "}
        </ButtonLink>
      </div>
    </div>
  );
}
