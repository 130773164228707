// eslint-disable-next-line no-restricted-imports -- Legacy
import { LinkBox, LinkOverlay } from "@chakra-ui/react";
import { Link as RemixLink } from "@remix-run/react";
import { LogoWithWordmark } from "~/ui/icons/logo-with-wordmark";
import { PrimaryBtn } from "~/ui/core/primary-button";
import { Button } from "~/ui/tracked/form/button";

// const navigation = [
//   { name: "Product", href: "#" },
//   { name: "Features", href: "#" },
//   { name: "Marketplace", href: "#" },
//   { name: "Company", href: "#" },
// ];

export function Header(): JSX.Element {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <a className="-m-1.5 p-1.5" href="/">
            <span className="sr-only">Double</span>
            {/* <img
              alt=""
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            /> */}
            {/* <LogoSymbol className="w-auto" boxSize="12" /> */}
            <LogoWithWordmark className="h-8 w-auto" />
          </a>
        </div>
        {/* <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              className="text-sm font-semibold leading-6 text-gray-900"
              href={item.href}
              key={item.name}
            >
              {item.name}
            </a>
          ))}
        </div> */}
        <div className="flex flex-1 items-center justify-end gap-x-6">
          {/* <a
            className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900"
            href="#"
          >
            Log in
          </a>
          <a
            className="rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            href="#"
          >
            Sign up
          </a> */}
          <LinkBox>
            <Button eventName="Clicked on Sign In" size="sm" variant="ghost">
              <LinkOverlay as={RemixLink} to="/login">
                Sign In
              </LinkOverlay>
            </Button>
          </LinkBox>
          <LinkBox>
            <PrimaryBtn eventName="Clicked on Sign Up" size="sm">
              <LinkOverlay as={RemixLink} to="/signup">
                Sign Up
              </LinkOverlay>
            </PrimaryBtn>
          </LinkBox>
        </div>
        {/* <div className="flex lg:hidden">
          <button
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
            type="button"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div> */}
      </nav>
      {/* <Dialog as="div" className="lg:hidden" onClose={setMobileMenuOpen} open={mobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <a className="-m-1.5 p-1.5" href="#">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              />
            </a>
            <a
              className="ml-auto rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              href="#"
            >
              Sign up
            </a>
            <button
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
              type="button"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    href={item.href}
                    key={item.name}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  href="#"
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog> */}
    </header>
  );
}
