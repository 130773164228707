import type { To } from "@remix-run/router";

export function extractPathname(input: To): string {
  if (typeof input === "string") {
    return input;
  } else if (typeof input === "object" && "pathname" in input) {
    return input.pathname ?? "";
  }

  return "";
}
